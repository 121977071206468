<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="hidden"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 30 30"
    class="group"
    aria-hidden="true"
  >
    <g transform="translate(0.754 1.732)">
      <path
        data-name="Path 3212"
        d="M17.05.584,28.644,12.275a1.851,1.851,0,0,1,0,2.728L17.05,26.694a2.046,2.046,0,0,1-2.631.195,1.851,1.851,0,0,1-.195-2.923L22.6,15.588H1.949a1.949,1.949,0,1,1,0-3.9H22.6L14.225,3.312A1.851,1.851,0,0,1,14.419.389,2.046,2.046,0,0,1,17.05.584Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
